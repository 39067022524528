const initState = {
    patterns: []
}

export default function (state = initState, action) {
    switch (action.type) {
        case "FETCH_FIELD_GROUP_PATTERNS":
            return { ...state, patterns: action.payload }

        default:
            return state;
    }
}