import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import banners from "./banner";
import fieldGroup from "./field-group";
import InternetShop from './InternetShop'
import Transfer from "./Transfer";

const reducers = combineReducers({
  shop: InternetShop,
  theme: Theme,
  auth: Auth,
  transfer: Transfer,
  banners,
  fieldGroup,
});

export default reducers;