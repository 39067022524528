import i18n from "i18next/i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationResource from "../lang/localesNew/translation.json"

const detectorOptions = {
    // order and from where user language should be detected
    order: ['localStorage', 'sessionStorage'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
}

const languageDetector = new LanguageDetector(null, detectorOptions)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: ["ru", "en"],
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    resources: translationResource,
    ns: ["translations"],
    defaultNS: "translations",
    compatibilityJSON: "v4",
    returnObjects: true
  });

export default i18n;