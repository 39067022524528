import {SET_BALANCE} from "../constants/Transfer";

const initialState = {
    balance: 0
}

const transfer = (state = initialState, action) => {
    switch(action.type) {
        case SET_BALANCE:
            return {
                ...state,
                balance: action.payload
            }
        default:
            return state
    }
}

export default transfer