import axios from "axios";
import { API_BASE_URL } from "../configs/AppConfig";
import { AUTH_TOKEN } from "../redux/constants/Auth";

axios.defaults.headers.accept = "application/json";

const axiosApiInstance = axios.create({
  baseURL: API_BASE_URL ,
  timeout: 1000 * 60 * 15,
});

axiosApiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token != null) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      Object.assign(config.headers, { "Content-Type": "multipart/form-data" });
    } else {
      Object.assign(config.headers, { "Content-Type": "application/json" });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401 || error.code == 401) {
      // localStorage.clear();
      // if (!["/", "/u/v", "/auth/login", "/auth/register", "/shop"].includes(window.location.pathname)) {
      //   window.location.href = "/";
      // }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosApiInstance;
