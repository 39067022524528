import {
  USER,
  LOGOUT_USER,
  SET_SHOP_PACKS,
  REMOVE_SHOP_PACKS,
} from "../constants/InternetShop";

const initState = {
  user: {},
  packs: [],
};
const shop = (state = initState, action) => {
  switch (action.type) {
    case USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
      };
    case SET_SHOP_PACKS:
      return {
        ...state,
        packs: action.payload,
      };
    case REMOVE_SHOP_PACKS:
      return {
        ...state,
        packs: [],
      };
    default:
      return state;
  }
};

export default shop;
