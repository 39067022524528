const initState = {
    banners: []
}

export default function (state = initState, action) {
    switch (action.type) {
        case "FETCH_BANNERS":
            return { ...state, banners: action.payload }

        default:
            return state;
    }
}