import requests from "../../requests/requests";

export const fetchBanners = () => {
    return dispatch => {
        return requests.getBanners()
            .then(({data}) => {
                dispatch({
                    type: 'FETCH_BANNERS',
                    payload: data.banners
                });
            })
    }
}