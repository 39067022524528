import {
    SIGNIN,
    AUTHENTICATED,
    SIGNOUT,
    SIGNOUT_SUCCESS,
    SHOW_AUTH_MESSAGE,
    HIDE_AUTH_MESSAGE,
    SIGNUP,
    SIGNUP_SUCCESS,
    SHOW_LOADING,
    SIGNIN_WITH_GOOGLE,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNIN_WITH_FACEBOOK,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    SET_CURRENT_USER,

    FETCH_USER_FAILURE,
    FETCH_USER_START,
    FETCH_USER_SUCCESS,

    LOGIN_FAIL,

    SET_PACK_ID,
    RELOAD,
    SET_CURRENCY_RATIO
} from '../constants/Auth';
import axios from '../../requests/client';
import requests from "../../requests/requests";

// account

const apiUrl = `/account`;

export const fetchUserStart = () => ({
    type: FETCH_USER_START
});

export const fetchUserSuccess = user => ({
    type: FETCH_USER_SUCCESS,
    payload: user
});

export const fetchUserFailure = errorMessage => ({
    type: FETCH_USER_FAILURE,
    payload: errorMessage
});

export const fetchUserStartAsync = () => {
    return dispatch => {
        dispatch(fetchUserStart())

        return Promise.all([
            axios.get(apiUrl),
            requests.getCurrencyRatios()
        ])
            .then(responses => {
                dispatch(fetchUserSuccess(responses[0].data))

                dispatch({
                    type: SET_CURRENCY_RATIO,
                    payload: responses[1].data
                });
            })
            .catch(error => dispatch(fetchUserFailure(error.message)));
    };
};

export const setPackId = (id) => {
    return {
        type: SET_PACK_ID,
        payload: id
    }
};


export const setLoginFail = () => {
    return {
        type: LOGIN_FAIL
    };
};

export const deleteCurrentUser = () => {
    return {
        type: LOGIN_FAIL
    }
};


export const setCurrentUser = (user) => {
    return {
        type: SET_CURRENT_USER,
        payload: user
    }
}

export const signIn = (user) => {
    return {
        type: SIGNIN,
        payload: user
    }
};

export const authenticated = (token) => {
    return {
        type: AUTHENTICATED,
        token
    }
};

export const signOut = () => {
    return {
        type: SIGNOUT
    };
};

export const reloadPage = () => {
    return {
        type: RELOAD
    };
};

export const signOutSuccess = () => {
    return {
        type: SIGNOUT_SUCCESS,
    }
};

export const signUp = (user) => {
    return {
        type: SIGNUP,
        payload: user
    };
};

export const signUpSuccess = (token) => {
    return {
        type: SIGNUP_SUCCESS,
        token
    };
};

export const signInWithGoogle = () => {
    return {
        type: SIGNIN_WITH_GOOGLE
    };
};

export const signInWithGoogleAuthenticated = (token) => {
    return {
        type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
        token
    };
};

export const signInWithFacebook = () => {
    return {
        type: SIGNIN_WITH_FACEBOOK
    };
};

export const signInWithFacebookAuthenticated = (token) => {
    return {
        type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
        token
    };
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_AUTH_MESSAGE,
        message
    };
};

export const hideAuthMessage = () => {
    return {
        type: HIDE_AUTH_MESSAGE,
    };
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING,
    };
};
