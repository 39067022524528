import httpClient from "./client";
import axios from "axios";
// * ip look up
// 'https://ipapi.co/json/'
const requests = {
  getWalletHistory: (userID, params) =>
    httpClient.get(`/users/${userID}/wallet-history`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      params
    })
  ,
  transferMoneyFromWallet: (data) => httpClient.post(`/balances/transfer`, data),
  verifyMoneyTransferFromWallet: (data) => httpClient.post("/balances/transfer/verify", data),
  deleteDraftSell: (purchaseId) => httpClient({
    method: "DELETE",
    url: `/purchases/${purchaseId}`
  }),
  getCurrentCountry: () =>
    axios.get(
      "http://ip-api.com/json"
    ),
  getCurrencyInfos: () => axios.get("https://nbu.uz/en/exchange-rates/json/"),
  getBanners: () => httpClient.get(`/banners`),
  getPromotions: () => httpClient.get(`/promotions`),
  getCurrencyRatios: () => httpClient.get(`/settings/currency-ratios`),
  bindCard: (data) => httpClient.post(`/bankcards/bind/request`, data),
  cardBindApply: (data) => httpClient.post(`/bankcards/bind/apply`, data),
  cardDelete: (data) => httpClient.post(`/bankcards/unbind`, data),
  getCards: () => httpClient.get(`/bankcards`),
  getCardLogs: () => httpClient.get(`/withdrawals`),
  requestWithdrawal: (data) => httpClient.post(`/withdrawals`, data),
  uploadWeddingCertificate: (file) =>
    httpClient.post(`/users/partner-attachment`, file),
  uploadSpousePassport: (file) =>
    httpClient.post(`/users/partner-passport-attachment`, file),
  getActiveInactiveTreeUsers: (userId) =>
    httpClient.get(`/users/${userId}/summary`),
  changePassword: (data) => httpClient.post(`/users/change-password`, data),
  uploadPassportImage: (file) =>
    httpClient.post(`/users/passport-attachment`, file),
  editUserData: (data) => httpClient.patch(`/users/edit`, data),
  uploadAvatar: (file) => httpClient.post(`/users/avatar`, file),
  getUser: () => httpClient.get(`/account`),
  login: (values) => httpClient.post(`/security/login`, values),
  registerInner: (values) => httpClient.post(`/users`, values),
  phoneVerify: (values) => httpClient.post(`/users/verify`, values),
  resendCode: (values) => httpClient.post(`/users/verification/resend`, values),
  getTreeUsersPending: () => httpClient.get(`/tree/pending`),
  getAncestors: (id, level) =>
    httpClient.get(`/tree/ancestors?user=${id}&level=${level}`),
  getTreeUsers: (id, depth) =>
    httpClient.get(`/tree?user=${id}&depth=${depth}`),
  addNewUserToTree: (values) => httpClient.post(`/tree/attach`, values),
  searchTreeUsers: (value) => httpClient.get(`/tree?search?q=${value}`),
  getUserLogs: (id) => httpClient.get(`/users/${id}/logs`),
  getUserSlaves: (id) => httpClient.get(`/users/${id}/slaves`),
  getPacksList: (page) => httpClient.get(`/packages?page=${page}`),
  getPackPurchaseLogs: (packId) => httpClient.get(`/purchases/${packId}`),
  getInvitedUsersPurchaseLogs: (packId) => httpClient.get(`/purchases/invited`),
  getLastPackPurchaseLog: () => httpClient.get(`/purchases`),
  getLastPackPurchaseLogById: (userId) =>
    httpClient.get(`/users/${userId}/purchases`),
  getUserBalance: () => httpClient.get(`/balance-history`),
  getTreeLeaf: () => httpClient.get(`/tree/leaf`),
  getTreeLeafPage: (page = 1) => httpClient.get(`/tree/leaf?page=${page}`),
  getAllUsers: (page = 1) => httpClient.get(`/tree/all?page=${page}`),
  listUsers: (queryParams) =>
    httpClient.get("/users", {
      params: queryParams,
    }),
  getUserByRefId: (referralId) => httpClient.get(`/users?referral=${referralId}`),
  currentBalance: () => httpClient.get("/current-balance"),
  pendingTotalWithdrawal: () => httpClient.get("/total-pending-withdrawals"),
  stats: (params) =>
    httpClient.get("/stats", {
      params,
    }),
  downloadStats: (type, data) =>
    httpClient.post(`/stats/download/${type}`, data, {
      responseType: "blob",
      timeout: 0,
    }),
  fieldGroupPatterns: () => httpClient.get("field-group-patterns"),
  checkExisting: (params) =>
    httpClient.get('/purchases/check-existing', {
      params,
    }),
  fetchOxData: (purchaseId) => httpClient.get(`/purchases/${purchaseId}/ox-data`),
  fetchServerTime: () => httpClient.get("/server-time"),
};

export default requests;
