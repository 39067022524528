import {
    AUTH_TOKEN,
    AUTHENTICATED,
    SHOW_AUTH_MESSAGE,
    HIDE_AUTH_MESSAGE,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    SHOW_LOADING,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    SET_CURRENT_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_START,
    FETCH_USER_FAILURE,

    LOGIN_FAIL,

    SET_PACK_ID,
    RELOAD, SET_CURRENCY_RATIO,
} from '../constants/Auth';

const initState = {
    loading: false,
    errorMessage: undefined,
    treeErrorMessage: '',
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN),
    currentUser: {all_statuses: [{type: ''}], phone_number: '', slaves: [], referer: {}},
    treeUsers: [],
    pendingTreeUser: [],
    registeredUsers: [],
    packId: 9,
    reload: false,
    currencyRatios: {}
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case RELOAD:
            return {
                ...state,
                reload: !state.reload
            }
        case SET_PACK_ID:
            return {
                ...state,
                packId: action.payload
            }
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false
            }
        case FETCH_USER_START:
            return {
                ...state,
                loading: true
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: '/app',
                token: action.token
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SIGNOUT_SUCCESS:
            return {
                ...state,
                token: null,
                redirect: '/',
                loading: false,
            }

        case SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.token
            }

        case SHOW_LOADING:
            return {
                ...state,
                loading: true
            }

        case SIGNIN_WITH_GOOGLE_AUTHENTICATED:
            return {
                ...state,
                loading: false,
                token: action.token
            }

        case SIGNIN_WITH_FACEBOOK_AUTHENTICATED:
            return {
                ...state,
                loading: false,
                token: action.token
            }

        case SET_CURRENCY_RATIO:
            return {
                ...state,
                currencyRatios: action.payload
            }

        default:
            return state;
    }
}

export default auth
