export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'

// export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_DATA = 'USER_DATA'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const FETCH_USER_START = 'FETCH_COLLECTIONS_START'
export const FETCH_USER_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_COLLECTIONS_FAILURE'

export const SET_PACK_ID = 'SET_PACK_ID'
export const RELOAD = 'RELOAD'
export const SET_CURRENCY_RATIO = 'SET_CURRENCY_RATIO'
