import requests from "../../requests/requests";

export const fetchFieldGroupPatterns = () => {
    return dispatch => {
        return requests.fieldGroupPatterns()
            .then(({data}) => {
                dispatch({
                    type: 'FETCH_FIELD_GROUP_PATTERNS',
                    payload: data.items
                });
            })
    }
}