import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers";
import thunk from 'redux-thunk';

const middlewares = [thunk];

function configureStore(preloadedState) {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers,  preloadedState, composeEnhancers(
    applyMiddleware(...middlewares)
  ));

  if (module.hot) {
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

const store = configureStore();
window.store = store

export default store;